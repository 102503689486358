<template>
  <!-- 系统菜单管理 -->
  <div class="menu">
    <h4>菜单列表</h4>
    <div style="padding-left: 30px">
      <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
    </div>
    <!-- <button @click="editDate">Edit</button>
		<button @click="addDate">Add</button>
		<button @click="delDate">Del</button> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [],
      defaultProps: {
        children: 'children',
        label: 'acitonName'
      }
    }
  },
  created() {
    this.getDate()
  },
  methods: {
    handleNodeClick(data) {
      console.log(data)
    },
    getDate() {
      let datas = []
      this.axios
        .get('/admin/action/selectActionList2', {})
        .then(res => {
          // console.log(res.data.data);
          this.data = res.data.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    editDate() {
      let datas = {
        acitonName: '实名认证',
        code: '3',
        ico: 'el-icon-s-custom',
        id: 17,
        parntId: 2,
        sort: 254,
        status: 1,
        urlAddress: 'custoMer'
      }
      this.axios
        .put('/admin/action/updateAction', datas)
        .then(res => {
          console.log(res)
          // this.data = res.data.data;
        })
        .catch(err => {
          console.log(err)
        })
    },
    addDate() {
      let datas = {
        acitonName: '会员管理',
        code: '2',
        ico: 'el-el-icon-s-tools',
        id: 26,
        parntId: 2,
        sort: 255,
        status: 1,
        urlAddress: 'member'
      }
      this.axios
        .post('/admin/action/addAction', datas)
        .then(res => {
          console.log(res)
          // this.data = res.data.data;
        })
        .catch(err => {
          console.log(err)
        })
    },
    delDate() {
      let datas = {
        ids: '27'
      }
      this.axios
        .delete('/admin/action/delAction', datas)
        .then(res => {
          console.log(res)
          // this.data = res.data.data;
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.menu {
  h4 {
    color: #409eff;
  }
}
</style>
